import Service from '@ember/service';
import { service } from '@ember/service';
import {
  task,
  timeout,
  waitForEvent,
  waitForProperty,
  race,
} from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import tippy from 'tippy.js';
import bucketItems from '../utils/bucket-items';
import { hideAll } from 'tippy.js';
import normalizeIdentifier from 'ember-stereo/-private/utils/normalize-identifier';
import { registerDestructor } from '@ember/destroyable';
export default class PlaybackService extends Service {
  @service stereo;
  @service nowPlaying;
  @service store;

  @tracked streamUrl;
  @tracked playerIsActive;
  @tracked blockedSound;
  @tracked blockedPrompt;

  constructor() {
    super(...arguments);
    this.addStereoMonitors();
    registerDestructor(this, this.teardown.bind(this));
  }

  addStereoMonitors() {
    this.stereo.on('audio-played', this.onAudioPlayed.bind(this));
    this.stereo.on(
      'audio-position-changed',
      this.onAudioPositionChanged.bind(this)
    );
    this.stereo.on(
      'audio-position-will-change',
      this.onAudioPositionChanged.bind(this)
    );
    this.stereo.on('audio-blocked', this.onAudioBlocked.bind(this));
  }

  teardown() {
    if (!this.stereo.isDestroyed) {
      try {
        this.stereo.off('audio-played', this.onAudioPlayed.bind(this));
        this.stereo.off(
          'audio-position-changed',
          this.onAudioPositionChanged.bind(this)
        );
        this.stereo.off(
          'audio-position-will-change',
          this.onAudioPositionChanged.bind(this)
        );
        this.stereo.off('audio-blocked', this.onAudioBlocked.bind(this));
      } catch (e) {
        // no op
      }
    }
  }

  onAudioPlayed() {
    this.playerIsActive = true;
  }

  onAudioBlocked({ sound }) {
    this.blockedSound = sound;
    this.handleAudioBlocked.perform(sound);

    this.stereo.on('audio-played', () => {
      this.playerIsActive = true;
      this.blockedSound = null;
      if (this.blockedPrompt) {
        hideAll();
        this.blockedSound = null;
        this.blockedPrompt = null;
      }
    });
  }

  stopAndClose() {
    this.stereo.stop();
    this.playerIsActive = false;
  }

  @task({ restartable: true })
  *handleAudioBlocked(sound) {
    let query = `[data-identifier="${normalizeIdentifier(
      sound.url
    )}"] .dubbletrack-player-controls`;
    var elements;
    var waitLength = 0;
    var pauseSize = 200;
    while (true) {
      elements = document.querySelectorAll(query);
      waitLength = waitLength + pauseSize;

      yield timeout(pauseSize);
      if ((elements && elements.length > 0) || waitLength > 3000) {
        break;
      }
    }

    let instances = tippy(elements, {
      role: 'tooltip',
      theme: 'attention',
      placement: 'bottom',
      maxWidth: '200px',
      animation: 'shift-away-subtle',
      hideOnClick: true,
      interactive: false,
      content:
        'Your browser blocked our autoplay attempt, so give this button a click to listen',
      trigger: 'manual',
      allowHTML: true,
    });

    this.blockedPrompt = instances[0];
    this.blockedPrompt?.show();
    yield timeout(1000);
  }

  onAudioPositionChanged({ sound }) {
    if (!sound.isDestroyed && !this.stereo.isDestroyed) {
      let airing = sound?.metadata?.airing;
      if (sound && sound?.metadata?.airing) {
        this.setCurrentMetadata.perform({ sound, airing });
      }
    }
  }

  @task({ drop: true, maxConcurrency: 1 })
  *setCurrentMetadata({ sound, airing }) {
    yield timeout(100);

    let {
      played = [],
      unplayed = [],
      playing = {},
    } = bucketItems(airing, sound);

    played.forEach((t) => {
      t.hasPlayed = true;
      t.isPlaying = false;
    });

    unplayed.forEach((t) => {
      t.isPlaying = false;
      t.hasPlayed = false;
    });

    playing.isPlaying = true;
    playing.hasPlayed = false;

    yield this.nowPlaying.updateAudioMetadata.perform(sound.url, {
      sound,
      airing,
      item: playing?.type ? playing : undefined,
      itemType: playing?.type,
    });
  }

  @task({ restartable: true })
  *playAiring(airing, trackStartTime) {
    // yield airing.load('broadcast', 'station', 'items');
    var sound = this.stereo.findSound(airing.playbackUrl);
    if (!sound) {
      try {
        var results = yield this.stereo.playTask.perform(airing.playbackUrl, {
          useConnections: ['HLS', 'NativeAudio'],
          metadata: { airing },
        });
      } catch (e) {
        throw new Error('failed to load sound');
      }
      sound = results?.sound;
    }
    yield this.setSoundPosition.perform({ airing, sound, trackStartTime });

    if (sound) {
      if (!sound.isPlaying && sound) {
        yield sound.togglePause();
      } else {
        // TODO: figure out better queuing
        // this.waitForNext.perform(sound);
      }
    }

    // yield sound.play();
    this.setCurrentMetadata.perform({ sound, airing });
    this.playerIsActive = true;

    yield timeout(200);
  }

  @task({ restartable: true })
  *playTrack(track) {
    // yield airing.load('broadcast', 'station', 'items');
    var sound = this.stereo.findSound(track.playbackUrl);
    if (!sound) {
      try {
        var results = yield this.stereo.playTask.perform(track.playbackUrl, {
          useConnections: ['HLS', 'NativeAudio'],
          metadata: { item: track },
        });
      } catch (e) {
        throw new Error('failed to load sound');
      }
      sound = results?.sound;
    }
    yield this.setSoundPosition.perform({ sound });

    if (sound) {
      if (!sound.isPlaying && sound) {
        yield sound.togglePause();
      } else {
        // TODO: figure out better queuing
        // this.waitForNext.perform(sound);
      }
    }

    // yield sound.play();
    this.setCurrentMetadata.perform({ sound, track });
    this.playerIsActive = true;

    yield timeout(200);
  }

  @task({ restartable: true })
  *setSoundPosition({ sound, airing, trackStartTime }) {
    if (
      trackStartTime &&
      typeof trackStartTime.getTime === 'function' &&
      trackStartTime.getTime()
    ) {
      var position;
      if (sound.currentTime) {
        position =
          sound.position +
          trackStartTime.getTime() -
          sound.currentTime.getTime();
      } else {
        position = trackStartTime.getTime() - airing.startsAt.getTime();
      }
      // position =
      // position - gapOffsetForPosition(airing, position) * 1000 + 10000;
      sound.position = position;
      yield timeout(200);
    } else if (trackStartTime && typeof trackStartTime === 'number') {
      sound.position = trackStartTime;
      yield timeout(200);
    }
  }

  @task({ restartable: true })
  *waitForNext(sound) {
    let loadedAirings = this.store.peekAll('airing');
    let time = sound.metadata.endsAt;
    let nextAiring = loadedAirings
      .filter((a) => {
        return (
          a.endsAt > time &&
          a.endsAt.getTime() - time.getTime() < 1000 * 60 &&
          !a.isOverridden
        );
      })
      .sortBy('startsAt')[0];

    if (!nextAiring) {
      nextAiring = yield this.store.queryRecord('airing', {
        filter: {
          ends_at: {
            gt: time.toISOString(),
          },
          active: true,
        },
        include: ['show', 'broadcast'],
        sort: 'starts_at',
        page: { size: 1, number: 1 },
      });
    }

    yield waitForEvent(sound, 'audio-ended');

    if (nextAiring) {
      this.playAiring.perform(nextAiring);
    }
  }
}
